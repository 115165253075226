import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { HOSTURL } from '@/plugins/http.service';
import axios from './api'
import { loading, snackbarMsg } from "@/plugins/global";

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));

function LayoutGrid() {
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState('')
  var data = new FormData();
  const [image, setImage] = useState('')
  const [newImage, setNewImage] = useState()
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [button, setButton] = useState('')
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [haveSubCategory, setHaveSubCategory] = useState(false)

  useEffect(() => {
    loading(true)
    axios.getCategories().then((res) => {
      setRows(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
  }, [])
  const columns = [
    {
      field: 'picture',
      headerName: 'Image',
      renderCell: (params) => <div style={{ display: 'flex', justifyContent: 'center' }}><img src={HOSTURL + "category/" + params.value} alt='' style={{ width: 40, padding: 3 }} /></div>,
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
          <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
          <IconButton onClick={() => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
        </div>
    },
  ];

  const UploadImg = (index) => {
    document.getElementById(index).click();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteCategory(id)
    } else if (button === 'Edit') {
      editCategory(id)
    }
    else {
      addcategory()
    }
  }
  const handleClickOpenPreview = (id) => {
    navigate('/admin/ads/categories/' + id);
  }
  const handleClickAdd = () => {
    setButton('Add')
    setOpen(true)
    setName('')
    setImage('');
  }
  const handleClickOpenEdit = (event, params) => {
    setButton('Edit')
    setOpen(true);
    setId(params.row.id);
    setName(params.row.name);
    setImage(params.row.picture)
  }
  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
    let subCategoryLength = params.row.categories.filter((cat)=>cat.archived===0).length
    if (subCategoryLength > 0) {
      setHaveSubCategory(true)
    }else{
      setHaveSubCategory(false)
    }
  }
  const deleteCategory = (id) => {
    axios.deleteCategory(id).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const addcategory = () => {
    data.append('picture', imageFile);
    data.append('name', name);
    axios.addCategory(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot add, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const editCategory = (id) => {
    if (imageFile !== '') {
      data.append('picture', imageFile);
    }
    data.append('name', name);
    axios.editCategory(id, data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot edit, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);


  return (
    <div className='big'>
      <div className='Title-container'>
        <h2>Ad Categories</h2>
        <button onClick={handleClickAdd}>Add</button>
      </div>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector

              columns={columns}
              rows={rows}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{button} category</DialogTitle>
          <DialogContent style={{ width: '300px' }}>
            {button !== 'Delete' && <div className='dialog-content-container'>
              <div className='dialog-row'>
                <TextField
                  margin="dense"
                  defaultValue={name}
                  id="idName"
                  label="Name"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 64 }}
                  onChange={(e) => { setName(e.target.value) }}
                />
                <div style={{ display: 'flex', marginTop: '15px' }}><AttachFileIcon alt="" onClick={() => { UploadImg("file") }} style={{ cursor: 'pointer' }} />Choose Images</div>
                <input type="file" accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG" id="file" className='inputFile' onChange={(e) => {
                  try {
                    let tmp = Image;
                    tmp = URL.createObjectURL(e.target.files[0]);
                    setImage(tmp);
                    setNewImage(tmp)
                    let file = e.target.files[0];
                    setImageFile(file);
                  }
                  catch (ex) {
                  }
                }} style={{ display: "none" }} />
              </div>
              <div style={{ marginTop: '10px' }}>
                {
                  newImage === undefined ? (
                    <>
                      {image !== '' && (
                        <img src={HOSTURL + "category/" + image} style={{ width: '100px' }} />
                      )}
                    </>
                  )
                    :
                    <img src={image} style={{ width: '100px' }} />
                }
              </div>
            </div>}
            {button === 'Delete' && (
              <>
              {haveSubCategory && (
                <div>This category has sub categories.</div>
              )}
            <div className='mt-5'>Are you sure you want to delete it?</div>
            </>
            )}
          </DialogContent>
          <DialogActions>
            <Button className='buttons' onClick={handleClose}>Cancel</Button>
            <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )



}

export default LayoutGrid