import { createBrowserRouter } from "react-router-dom";
import UserLayout from '../layouts/user-layout';
import DefaultPage from '../views/default-page'
import Users from '../views/users/all-users';
import UserPreview from '../views/users/user-preview'
import Categories from '../views/ads/categories'
import Courses from '@/views/courses/all-courses';
import Sub from '../views/ads/sub-categories';
import CoursePreview from '../views/courses/course-preview';
import CourseCategories from '../views/courses/categories';
import CourseCatPreview from '../views/courses/sub-categories';
import AllAds from '../views/ads/all-ads';
import NonApprovedAds from '../views/ads/non-approved-ads';
import Conditions from '../views/ads/conditions';
import Locations from '../views/ads/locations';
import Content from '../views/settings/content';
import ContentPreview from '../views/settings/content-preview';
import Settings from '../views/settings/sub-settings';
import NotFound from "../views/front/notFound";
import Sizes from '../views/ads/sizes';
import SingleAd from '../views/ads/single-ad';
import Subscriptions from '../views/users/subscriptions';
import SingleSub from '../views/users/single-sub';
import Packages from '@/views/settings/packages';
import SinglePackage from '../views/settings/single-package';
import Transactions from '../views/users/transactions';
import Coupon from '../views/users/coupon';
import SingleCoupon from '../views/users/single-coupon';
import Notifications from '../views/settings/notifications';
import SingleTransaction from '../views/users/single-transaction';
import Login from '../views/front/login';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "*",
    element:<NotFound /> ,
  },
  {
    path: "/",
    element:<UserLayout><UserPage children={<Users />} /></UserLayout> ,
  },
  {
    path: "/admin/users/all-users",
    element: <UserLayout><UserPage children={<Users />} /></UserLayout>,
  },
  {
    path: 'admin/ads/categories',
    element: <UserLayout><UserPage children={<Categories />} /></UserLayout>,
  },
  {
    path: '/admin/ads/categories/:id',
    element: <UserLayout><UserPage children={<Sub />} /></UserLayout>,
  },
  {
    path: '/admin/user-preview/:id',
    element: <UserLayout><UserPage children={<UserPreview />} /></UserLayout>,
  },
  {
    path: 'admin/courses/all-courses',
    element: <UserLayout><UserPage children={<Courses />} /></UserLayout>,
  },
  {
    path: '/admin/courses/single-course/:id',
    element: <UserLayout><UserPage children={<CoursePreview />} /></UserLayout>,
  },
  {
    path: 'admin/courses/categories',
    element: <UserLayout><UserPage children={<CourseCategories />} /></UserLayout>,
  },
  {
    path: '/admin/courses/categories/:id',
    element: <UserLayout><UserPage children={<CourseCatPreview />} /></UserLayout>,
  },
  {
    path: '/admin/ads/all-ads/',
    element: <UserLayout><UserPage children={<AllAds />} /></UserLayout>,
  },
  {
    path: '/admin/ads/non-approved-ads/',
    element: <UserLayout><UserPage children={<NonApprovedAds />} /></UserLayout>,
  },
  {
    path: '/admin/ads/conditions/',
    element: <UserLayout><UserPage children={<Conditions />} /></UserLayout>,
  },
  {
    path: '/admin/ads/locations/',
    element: <UserLayout><UserPage children={<Locations />} /></UserLayout>,
  },
  {
    path: '/admin/settings/content/',
    element: <UserLayout><UserPage children={<Content />} /></UserLayout>,
  },
  {
    path: '/admin/settings/content/:id',
    element: <UserLayout><UserPage children={<ContentPreview />} /></UserLayout>,
  },
  {
    path: '/admin/settings/settings/',
    element: <UserLayout><UserPage children={<Settings />} /></UserLayout>,
  },
  {
    path: '/admin/ads/sizes/',
    element: <UserLayout><UserPage children={<Sizes />} /></UserLayout>,
  },
  {
    path: '/admin/ads/single-ad/:id',
    element: <UserLayout><UserPage children={<SingleAd />} /></UserLayout>,
  },
  {
    path: '/admin/users/subscriptions/',
    element: <UserLayout><UserPage children={<Subscriptions />} /></UserLayout>,
  },
  {
    path: '/admin/users/single-subscription/:id',
    element: <UserLayout><UserPage children={<SingleSub />} /></UserLayout>,
  },
  {
    path: '/admin/settings/packages/',
    element: <UserLayout><UserPage children={<Packages />} /></UserLayout>,
  },
  {
    path: '/admin/settings/single-package/:id',
    element: <UserLayout><UserPage children={<SinglePackage />} /></UserLayout>,
  },
  {
    path: '/admin/users/transactions/',
    element: <UserLayout><UserPage children={<Transactions />} /></UserLayout>,
  },
  {
    path: '/admin/users/coupon/',
    element: <UserLayout><UserPage children={<Coupon />} /></UserLayout>,
  },
  {
    path: '/admin/settings/notifications/',
    element: <UserLayout><UserPage children={<Notifications />} /></UserLayout>,
  },
  {
    path: '/admin/users/single-coupon/:id',
    element: <UserLayout><UserPage children={<SingleCoupon />} /></UserLayout>,
  },
  {
    path: '/admin/users/single-transaction/:id',
    element: <UserLayout><UserPage children={<SingleTransaction />} /></UserLayout>,
  },
  {
    path: '/admin/login',
    element: <Loginn children = {<Login />}/>,
  },

]);

function UserPage({ children }) {
  const navigator = useNavigate()
  let admin = localStorage.getItem("kinderlandAdmin");
  useEffect(() => {
    if (!admin) {
      navigator("/admin/login")
    }
  }, [admin, navigator])
  return children
}
function Loginn({ children }) {
  let admin = localStorage.getItem("kinderlandAdmin");
 const navigate = useNavigate();
useEffect(()=>{
  if (admin) {
    navigate('/admin/users/all-users')
   }
},[admin,navigator]);
 return children
}

export default router;
