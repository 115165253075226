import axios from '@/plugins/http.service';

const axiosFunction = {
    getConditions() {
        return axios().get('condition/get-all');
    },
    editCondition(id, data) {
        return axios().post('condition/update/' + id, data);
    },
    deleteCondition(data) {
        return axios().post('condition/delete/', data);
    },
    addCondition(data) {
        return axios().post('condition/add', data);
    }
}
export default axiosFunction