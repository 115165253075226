import { useParams } from 'react-router-dom';
import axios from './api';
import { useState, useEffect } from 'react';
import { loading } from "@/plugins/global";
import { useNavigate } from "react-router-dom";
import { minHeight } from '@mui/system';

function AdPreview() {
    const params = useParams();
    const navigate = useNavigate();
    const [row, setRow] = useState({});
    useEffect(() => {
        loading(true);
        axios.getAdById(params.id).then((res) => {
            setRow(res.data.data[0])
            loading(false);
        }).catch(function (error) {
            console.log(error)
            loading(false);
        })
    }, [params.id])

    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>Ad {row.name} Details</h2>
                <button className='back' onClick={() => navigate(-1)}>Back</button>
            </div>
            <div className='info'>
                <div className='data'>
                    <span className='split left'><p><b>Name: </b>{row.name}</p></span>
                    <span className='split right'><p><b>User:  </b>{row.user?.email}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Category: </b>{row.category?.name}</p></span>
                    <span className='split right'><p><b>Location: </b>{row.location?.name}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Condition: </b>{row.condition?.name}</p></span>
                    <span className='split right'><p><b>Price: </b>{row.price}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b> Size: </b>{row.size?.name}</p></span>
                    <span className='split right'><p><b>Status: </b>{row.status}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Approved: </b>{row.approved === 1 ? ' Yes' : ' No'}</p></span>
                    <span className='split right'><p><b>Brand: </b>{row.brand}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Description: </b>{row.description}</p></span>
                </div>
            </div>
        </div>

    )
}
export default AdPreview