import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import axios from './api'
import { loading, snackbarMsg } from "@/plugins/global";

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));

function LayoutGrid() {
  var data = new FormData();
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [parentCategoryObj, setParentCategoryObj] = useState('')
  const [subCats, setSubCats] = useState([])
  const [button, setButton] = useState('')
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    loading(true)
    axios.getSizes().then((res) => {
      setRows(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
    axios.getSubCategories().then((res) => {
      setSubCats(res.data.data)
    })
  }, [])
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
          <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
        </div>
    },
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteSize(id)
    } else if (button === 'Edit') {
      editSize(id)
    }
    else {
      addSize()
    }
  }
  const handleClickAdd = () => {
    setButton('Add')
    setOpen(true)
    setName('')
  }
  const handleClickOpenEdit = (event, params) => {
    setButton('Edit')
    setOpen(true);
    setId(params.row.id);
    setName(params.row.name);
    // var cat = subCats.map((cat) => cat.id === params.row.categoryId ? cat.name : '')
    // if (params.row.categoryId !== null) {
    //   for (let i = 0; i < cat.length; i++) {
    //     if (cat[i] !== '') {
    //       setParentCategoryObj({ id: params.row.categoryId, name: cat[i] });
    //     }
    //   }
    // } else {
    //   setParentCategoryObj({ id: '', name: '' });
    // }
    // setCategoryId(params.row.categoryId);
  }
  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
  }
  const deleteSize = (id) => {
    data.append('id', id)
    axios.deleteSize(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const addSize = () => {
    data.append('name', name);
    // data.append('categoryId', categoryId);
    axios.addSize(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot add, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const editSize = (id) => {
    data.append('name', name);
    // data.append('categoryId', categoryId);
    axios.editSize(id, data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot edit, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);

  return (
    <div className='big'>
      <div className='Title-container'>
        <h2>Sizes</h2>
        <button onClick={handleClickAdd}>Add</button>
      </div>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector

              columns={columns}
              rows={rows}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{button} Size</DialogTitle>
          <DialogContent sx={{ width: '400px' }}>
            {button !== 'Delete' && <div className='dialog-content-container'>
              <div className='dialog-row'>
                <TextField
                  margin="dense"
                  defaultValue={name}
                  id="idName"
                  label="Name"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 24 }}
                  onChange={(e) => { setName(e.target.value) }}
                />
              </div>
            </div>}
            {button === 'Delete' && <p>Are you sure you want to delete it?</p>}
          </DialogContent>
          <DialogActions>
            <Button className='buttons' onClick={handleClose}>Cancel</Button>
            <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )



}

export default LayoutGrid