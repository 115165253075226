import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { HOSTURL } from '@/plugins/http.service';
import axios from './api'
import { loading, snackbarMsg } from "@/plugins/global";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));

function LayoutGrid() {
  const navigate = useNavigate();
  var data = new FormData();
  const [id, setId] = useState('')
  const [duration, setDuration] = useState('')
  const [price, setPrice] = useState('')
  const [name, setName] = useState('')
  const [button, setButton] = useState('')
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    loading(true)
    axios.getPackages().then((res) => {
      setRows(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
  }, [])
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'duration',
      headerName: 'Duration (days)',
      flex: 1,
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      flex: 1,
      renderCell: (params) => <div>{params.row.createdDate.slice(0,10)}</div> 
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Last updated date',
      flex: 1,
      renderCell: (params) => <div>{params.row.lastUpdatedDate !== undefined && params.row.lastUpdatedDate !== null ? params.row.lastUpdatedDate.slice(0,10) : ''}</div> 
    },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
          <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
          <IconButton onClick={() => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
        </div>
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteCourse(id)
    } else if (button === 'Edit') {
      editCourse(id)
    }
    else {
      addcourse()
    }
  }
  const handleClickOpenPreview = (id) => {
    navigate('/admin/settings/single-package/' + id);
  }
  const handleClickAdd = () => {
    setButton('Add')
    setOpen(true)
    setName('')
    setPrice('');
    setDuration('');
  }
  const handleClickOpenEdit = (event, params) => {
    setButton('Edit');
    setOpen(true);
    setId(params.row.id);
    setName(params.row.name);
    setPrice(params.row.price);
    setDuration(params.row.duration);
  }
  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
  }
  const deleteCourse = (id) => {
    data.append('id', id);
    axios.deletePackage(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const addcourse = () => {
    data.append('name', name);
    data.append('price', price);
    data.append('duration', duration);
    axios.addPackage(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot add, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const editCourse = (id) => {
    data.append('name', name);
    data.append('price', price);
    data.append('duration', duration);
    axios.editPackage(id, data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot edit, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);

  return (
    <div className='big'>
      <div className='Title-container'>
        <h2>Packages</h2>
        <button onClick={handleClickAdd}>Add</button>
      </div>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector

              columns={columns}
              rows={rows}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose} >
          <DialogTitle>{button} Package </DialogTitle>
          <DialogContent>
            {button !== 'Delete' && <div className='dialog-content-container'>
              <div className='dialog-row'>
                <TextField
                  margin="dense"
                  defaultValue={name}
                  label="Name"
                  inputProps={{ maxLength: 64 }}
                  fullWidth
                  variant="standard"
                  onChange={(e) => { setName(e.target.value) }}
                />
                <TextField
                  margin="dense"
                  defaultValue={price}
                  label="Price"
                  fullWidth
                  variant="standard"
                  onChange={(e) => { setPrice(e.target.value) }}
                />
                <TextField
                  margin="dense"
                  defaultValue={duration}
                  label="Duration (days)"
                  fullWidth
                  variant="standard"
                  onChange={(e) => { setDuration(e.target.value) }}
                />
              </div>
            </div>}
            {button === 'Delete' && <p>Are you sure you want to delete it?</p>}
          </DialogContent>
          <DialogActions>
            <Button className='buttons' onClick={handleClose}>Cancel</Button>
            <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )



}

export default LayoutGrid