import axios from '@/plugins/http.service';

const axiosFunction = {
    getLocations() {
        return axios().get('location/get-all');
    },
    editLocation(id, data) {
        return axios().post('location/update/' + id, data);
    },
    deleteLocation(data) {
        return axios().post('location/delete/', data);
    },
    addLocation(data) {
        return axios().post('location/add', data);
    }
}
export default axiosFunction