import * as React from 'react';
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useState, useEffect } from 'react';
import { HOSTURL } from '@/plugins/http.service';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from './api'
import { loading, snackbarMsg } from "@/plugins/global";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './style.css';

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));

function LayoutGrid() {
  const navigate = useNavigate();
  var data = new FormData();
  const [picture, setImage] = useState(null)
  const [imageFile, setImageFile] = useState('')
  const [newImage, setNewImage] = useState('');
  const [id, setId] = useState('')
  const [summary, setSummary] = useState('')
  const [catOp, setCatOp] = useState('')
  const [content, setContent] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [link, setLink] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [filterCategoryId, setFilterCategoryId] = useState('')
  const [name, setName] = useState('')
  const [button, setButton] = useState('')
  const [open, setOpen] = useState(false)
  const [categoryParent, setCategoryParent] = useState([])
  const [parentCategoryObj, setParentCategoryObj] = useState('')
  const [rows, setRows] = useState([])

  useEffect(() => {
    loading(true)
    if (filterCategoryId !== '') {
      data.append('categoryId', filterCategoryId)
    }
    axios.getCourses(data).then((res) => {
      setRows(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
    axios.getSubCategories().then((res) => {
      let tmp = []
      res.data.data.forEach((el) => {
        if (el.parentId == null) {
          tmp.push({ id: el.id, group: el.name, name: '--any--' })

        } else {
          tmp.push({ id: el.id, group: el.parent.name, name: el.name })

        }
        tmp.sort((a, b) => (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0))
      })

      console.log(tmp)


      setCategoryParent(tmp)
      console.log(res.data.data)

    })
  }, [])
  const columns = [
    {
      field: 'picture',
      headerName: 'Image',
      renderCell: (params) => <div style={{ display: 'flex', justifyContent: 'center' }}><img src={HOSTURL + "course/" + params.value} alt='' style={{ width: 40, padding: 3 }} /></div>,
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'categoryId',
      headerName: 'Category',
      flex: 1,
      renderCell: (params) => <div>{params.row.category.name}</div>
    },
    {
      field: 'link',
      headerName: 'Link',
      flex: 1,
      renderCell: (params) => <div><Link href={params.row.link} target="_blank" rel="noopener noreferrer" color="inherit">{params.row.link}</Link></div>
    },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
          <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
          <IconButton onClick={() => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
        </div>
    },
  ];
  const UploadImg = (index) => {
    document.getElementById(index).click();
  };
  const filter = () => {
    loading(true);
    if (filterCategoryId !== null) {
      data.append('categoryId', filterCategoryId)
      axios.getCourses(data).then((res) => {
        setRows(res.data.data);
        loading(false);
      })
    } else {
      axios.getCourses(data).then((res) => {
        setRows(res.data.data)
        loading(false)
      }).catch(function (error) {
        loading(false)
      })
    }
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Stack direction="row" spacing={3} style={{ alignItems: 'center' }}>
          <Autocomplete
            sx={{ width: '180px', margin: 1 }}
            options={categoryParent}
            groupBy={(option) => option.parentId}
            getOptionLabel={(option) => option.name || ''}
            onChange={(event, value) => {
              if (value === null) {
                setFilterCategoryId(null);
                setCatOp('')
              } else {
                setFilterCategoryId(value.id);
                setCatOp(value)
              }
            }}
            value={catOp}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Category"
                sx={{
                  '.MuiInputBase-root': { padding: 0 },
                }}
              />
            )}
          />
          <Button className='button' onClick={filter}>Filter</Button>
        </Stack>
        <GridToolbarQuickFilter
          sx={{ border: '#D18D6B' }}
          quickFilterParser={(searchInput) =>
            searchInput.split(',').map((value) => value.trim())
          }
          quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
          debounceMs={200}
        />
      </GridToolbarContainer>
    );
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteCourse(id)
    } else if (button === 'Edit') {
      editCourse(id)
    }
    else {
      addcourse()
    }
  }
  const handleClickOpenPreview = (id) => {
    navigate('/admin/courses/single-course/' + id);
  }
  const handleClickAdd = () => {
    setButton('Add')
    setOpen(true)
    setName('')
    setName('');
    setSummary('');
    setContent('');
    setEditorState('')
    setLink('');
    setCategoryId('');
    setParentCategoryObj({ id: '', name: '' });
    setImage(null)
  }
  const handleClickOpenEdit = (event, params) => {
    setButton('Edit');
    setOpen(true);
    setId(params.row.id);
    setName(params.row.name);
    setSummary(params.row.summary);
    setImage(params.row.picture)
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(params.row.content))))
    setLink(params.row.link);
    var cat = categoryParent.map((cat) => cat.id === params.row.categoryId ? cat.name : '')
    if (params.row.categoryId !== null) {
      for (let i = 0; i < cat.length; i++) {
        if (cat[i] !== '') {
          setParentCategoryObj({ id: params.row.categoryId, name: cat[i] });
        }
      }
    } else {
      setParentCategoryObj({ id: '', name: '' });
    }
    setCategoryId(params.row.categoryId);
  }
  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
  }
  const deleteCourse = (id) => {
    data.append('id', id);
    axios.deleteCourse(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const addcourse = () => {
    data.append('picture', imageFile);
    data.append('name', name);
    data.append('summary', summary);
    data.append('content', stateToHTML(editorState.getCurrentContent()));
    data.append('link', link);
    data.append('categoryId', categoryId);
    axios.addCourse(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot add, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const editCourse = (id) => {
    if (imageFile !== '') {
      data.append('picture', imageFile);
    }
    data.append('name', name);
    data.append('summary', summary);
    data.append('content', stateToHTML(editorState.getCurrentContent()));
    data.append('link', link);
    data.append('categoryId', categoryId);
    axios.editCourse(id, data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot edit, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  };

  return (
    <div className='big'>
      <div className='Title-container'>
        <h2>Courses</h2>
        <button onClick={handleClickAdd}>Add</button>
      </div>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector

              columns={columns}
              rows={rows}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose} >
          <DialogTitle>{button} Course </DialogTitle>
          <DialogContent>
            {button !== 'Delete' && <div className='dialog-content-container'>
              <div className='dialog-row'>
                <Autocomplete
                  options={categoryParent}
                  groupBy={(option) => option.group}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    if (value === null) {
                      setCategoryId(null);
                    } else {
                      setCategoryId(value.id);
                    }
                  }}
                  defaultValue={parentCategoryObj}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Category"
                    />
                  )}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  defaultValue={name}
                  id="idName"
                  label="Name"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 64 }}
                  onChange={(e) => { setName(e.target.value) }}
                />
                <TextField
                  margin="dense"
                  defaultValue={summary}
                  id="idSummary"
                  label="Summary"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 250 }}
                  onChange={(e) => { setSummary(e.target.value) }}
                />
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  placeholder="Content"
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove']
                  }}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
                <TextField
                  margin="dense"
                  defaultValue={link}
                  id="idLink"
                  label="Link"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 80 }}
                  onChange={(e) => { setLink(e.target.value) }}
                />
                <div style={{ display: 'flex' }}><AttachFileIcon alt="" onClick={() => { UploadImg("file") }} style={{ cursor: 'pointer' }} />Choose Images</div>
                <input type="file" accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG" id="file" className='inputFile' onChange={(e) => {
                  try {
                    let tmp = Image;
                    tmp = URL.createObjectURL(e.target.files[0]);
                    setImage(tmp);
                    setNewImage(tmp)
                    let file = e.target.files[0];
                    setImageFile(file);
                  }
                  catch (ex) {
                  }
                }} style={{ display: "none" }} />
              </div>
              <div style={{ marginTop: '10px' }}>
                {
                  newImage === '' ? (
                    <>
                    {picture &&
                      <img src={HOSTURL + "course/" + picture} style={{ width: '100px' }} />
                    }
                    </>
                  )
                    : (
                      <img src={picture} style={{ width: '100px' }} />
                    )
                }
              </div>
            </div>}
            {button === 'Delete' && <p>Are you sure you want to delete it?</p>}
          </DialogContent>
          <DialogActions>
            <Button className='buttons' onClick={handleClose}>Cancel</Button>
            <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )



}

export default LayoutGrid