import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from './api'
import './style.css'
import { loading, snackbarMsg } from "../../../plugins/global";
import { useState, useContext } from 'react';
import UserContext from "@/context/user";
import { useNavigate } from "react-router-dom";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import logo from './../../../assets/image/logo.png'
function Login(){
    
    const navigate = useNavigate();
    const { login } = useContext(UserContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);


    const loginEvent = () => {
        if (email === '' || password === '') {
            snackbarMsg("All fields are required");
          }else{
        loading(true);
        let data = {
            email,
            password,
            type:'2'
        };

        axios.logIn(data).then((res) => {
            if (res.data.message === 'success'){
                login(res.data.data)
                loading(false);
                navigate("/admin/users/all-users");
            }
            })
            .catch((e) => {
                snackbarMsg("Wrong email or password");
                setEmail("");
                setPassword("");
                loading(false);
            });
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="login-page">
                <div className="login-card">
                    <img src={logo} className="logo"/>
                    <h1 style={{margin:0}}>LOGIN</h1>
                    <TextField
                        placeholder="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    ></TextField>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        fullWidth
                        placeholder="Password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <Button style={{background:'var(--primary-color)'}}
                        variant="contained"
                        onClick={() => {
                            loginEvent();
                        }}
                    >Login
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default Login