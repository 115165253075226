import axios from '@/plugins/http.service';

const axiosFunction = {
    getCategories() {
        return axios().get('course-category/master');
    },
    editCategory(id, data) {
        return axios().post('course-category/update/' + id, data);
    },
    deleteCategory(id) {
        return axios().get('course-category/delete/' + id);
    },
    getCategoryById(id) {
        return axios().get('course-category/' + id);
    },
    addCategory(data) {
        return axios().post('course-category', data);
    }
}
export default axiosFunction