import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import axios from './api'
import { loading, snackbarMsg } from "@/plugins/global";

const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    }
}));

function LayoutGrid() {
    var data = new FormData();
    const [id, setId] = useState('')
    const [key, setKey] = useState('')
    const [value, setValue] = useState('')
    const [button, setButton] = useState('')
    const [open, setOpen] = useState(false)
    const [rows, setRows] = useState([])

    useEffect(() => {
        loading(true)
        axios.getSettings().then((res) => {
            setRows(res.data.data)
            loading(false)
        }).catch(function (error) {
            console.log(error)
            loading(false)
        })
    }, [])
    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
        },
        {
            field: 'key',
            headerName: 'Key',
            flex: 1,
        },
        {
            field: 'val',
            headerName: 'Value',
            flex: 1,
        },
        {
            field: 'Actions',
            flex: 1,
            headerName: 'Actions',
            renderCell: (params) =>
                <div className='actions'>
                    <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
                    <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
                </div>
        },
    ];
    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = () => {
        if (button === 'Delete') {
            deleteSettings(id)
        } else if (button === 'Edit') {
            editSettings(id)
        }
        else {
            addSettings()
        }
    }
    const handleClickAdd = () => {
        setButton('Add')
        setOpen(true)
        setKey('')
        setValue('')
    }
    const handleClickOpenEdit = (event, params) => {
        setButton('Edit')
        setOpen(true);
        setId(params.row.id);
        setKey(params.row.key);
        setValue(params.row.val);
    }
    const handleClickOpenDelete = (event, params) => {
        setButton('Delete')
        setId(params.row.id)
        setOpen(true);
    }
    const deleteSettings = (id) => {
        data.append('id', id);
        axios.deleteSettings(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            snackbarMsg("Cannot delete, something is wrong");
            console.log(error)
        });
        setOpen(false);
    }
    const addSettings = () => {
        data.append('key', key);
        data.append('val', value);
        axios.addSettings(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            snackbarMsg("Cannot add, something is wrong");
            console.log(error)
        });
        setOpen(false);
    }
    const editSettings = (id) => {
        data.append('key', key);
        data.append('val', value);
        axios.editSettings(id, data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            snackbarMsg("Cannot edit, something is wrong");
            console.log(error)
        });
        setOpen(false);
    }
    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 6,
            bottom: params.isLastVisible ? 0 : 6,
        };
    }, []);

    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>Settings</h2>
                <button onClick={handleClickAdd}>Add</button>
            </div>
            <div style={{ height: 700, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <StripedDataGrid
                            sx={{
                                border: 'none',
                                height: '55em',
                            }}
                            getRowHeight={() => 'auto'}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector

                            columns={columns}
                            rows={rows}
                            getRowSpacing={getRowSpacing}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </div>
                </div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{button} Settings</DialogTitle>
                    <DialogContent sx={{ width: '400px' }}>
                        {button !== 'Delete' && <div className='dialog-content-container'>
                            <div className='dialog-row'>
                                <TextField
                                    margin="dense"
                                    defaultValue={key}
                                    label="Key"
                                    fullWidth
                                    variant="standard"
                                    inputProps={{ maxLength: 20 }}
                                    onChange={(e) => { setKey(e.target.value) }}
                                />
                                 <TextField
                                    margin="dense"
                                    defaultValue={value}
                                    label="Value"
                                    fullWidth
                                    variant="standard"
                                    inputProps={{ maxLength: 64 }}
                                    onChange={(e) => { setValue(e.target.value) }}
                                />
                            </div>
                        </div>}
                        {button === 'Delete' && <p>Are you sure you want to delete it?</p>}
                    </DialogContent>
                    <DialogActions>
                        <Button className='buttons' onClick={handleClose}>Cancel</Button>
                        <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )



}

export default LayoutGrid