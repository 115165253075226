import axios from "axios";
//let API_ENDPOINT = "http://157.175.235.55:5000/"; 
//const HOSTURL = "http://157.175.235.55/api/uploads/";

let API_ENDPOINT = "https://dashboard.kidsmarket.io/api/";
const HOSTURL = "https://dashboard.kidsmarket.io/api/uploads/";

var httpClient = () => {
  let config = {
    baseURL: `${API_ENDPOINT}`,
    headers: {
      "Authorization":JSON.parse(localStorage.getItem("kinderlandAdmin"))?.hash,
      "user-id":JSON.parse(localStorage.getItem("kinderlandAdmin"))?.userId
    }
  };
  return axios.create(config);
}

export { API_ENDPOINT };
export { HOSTURL };
export default httpClient;