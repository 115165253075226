import { RouterProvider } from "react-router-dom";
import router from './router';
import { useState, useEffect } from 'react';
import UserContext from "./context/user";
import AppInfo from './components/app-info';
import createMuiTheme from '@mui/material/styles/createTheme';
import { ThemeProvider } from "@emotion/react";
import createTheme from '@mui/material/styles/createTheme';

import './App.css';

function App() {

  const [admin, setAdmin] = useState(localStorage.getItem("kinderlandAdmin") ? JSON.parse(localStorage.getItem("kinderlandAdmin")) : null);

  useEffect(() => { }, [])

  function login(value) {
      localStorage.setItem("kinderlandAdmin", JSON.stringify(value));
      setAdmin(value)
  }
  function logout() {
      localStorage.removeItem("kinderlandAdmin");
      setAdmin(null)
  }
  const theme = createTheme({
    palette: {
        primary: {
            main: "#D18D6B"
        }
    },
  })
  return (
    <div>
      {/* <ThemeProvider theme={theme}> */}
      <UserContext.Provider value={{ login: login, logout: logout, admin: admin }}>
        <RouterProvider router={router}></RouterProvider>
      </UserContext.Provider>
      <AppInfo />
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;
