import axios from '@/plugins/http.service';

const axiosFunction = {
    getSizes() {
        return axios().get('size/get-all');
    },
    editSize(id,data) {
        return axios().post('size/update/'+id,data);
    },
    deleteSize(data) {
        return axios().post('size/delete/', data);
    },
    addSize(data) {
        return axios().post('size/add',data);
    },
    getCategories() {
        return axios().get('category/master');
    },
    getSubCategories() {
        return axios().get('category/all-children/');
    }
}
export default axiosFunction