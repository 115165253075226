import axios from '@/plugins/http.service';

const axiosFunction = {
    getAdById(id) {
        return axios().get('ad/details/' + id);
    },
    getCategoryById(id) {
        return axios().get('category/'+id);
    },
    getUserById(id) {
        return axios().get('user/details/'+id);
    },
}
export default axiosFunction