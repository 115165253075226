import axios from '@/plugins/http.service';

const axiosFunction = {
    getPackages() {
        return axios().get('package/get-all');
    },
    editPackage(id, data) {
        return axios().post('package/update/' + id, data);
    },
    deletePackage(data) {
        return axios().post('package/delete/', data);
    },
    addPackage(data) {
        return axios().post('package/add', data);
    },
}
export default axiosFunction