import axios from '@/plugins/http.service';

const axiosFunction = {
    getSettings() {
        return axios().get('settings/get-all');
    },
    editSettings(id, data) {
        return axios().post('settings/update/' + id, data);
    },
    deleteSettings(data) {
        return axios().post('settings/delete/', data);
    },
    addSettings(data) {
        return axios().post('settings/add', data);
    }
}
export default axiosFunction