import { useParams } from 'react-router-dom';
import axios from './api';
import { useState, useEffect, useCallback } from 'react';
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { loading } from "@/plugins/global";
import { useNavigate } from "react-router-dom";

const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    }
}));

function UserPreview() {
    const params = useParams();
    var data = new FormData();
    const navigate = useNavigate();
    const [row, setRow] = useState([]);
    const [adRows, setAdRows] = useState([]);
    const [subRows, setSubRows] = useState([]);
    useEffect(() => {
        loading(true);
        axios.getUserById(params.id).then((res) => {
            setRow(res.data.data)
            setAdRows(res.data.data.ads)
            setSubRows(res.data.data.subscriptions)
            loading(false);
        }).catch(function (error) {
            console.log(error)
            loading(false);
        })
    }, [])
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => <div>{params.row.name}</div>
        },
        {
            field: 'brand',
            headerName: 'Brand',
            flex: 1,
            renderCell: (params) => <div>{params.row.brand}</div>
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => <div>{params.row.price}</div>
        },
        {
            field: 'condition',
            headerName: 'Condition',
            flex: 1,
            renderCell: (params) => <div>{params.row.condition.name}</div>
        },
        {
            field: 'size',
            headerName: 'Size',
            flex: 1,
            renderCell: (params) => <div>{params.row?.size?.name == undefined ? '-' : params.row?.size?.name}</div>
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => <div>{params.row.status === 0 ? 'Pending' : params.row.status === 1 ? 'Subscription' : params.row.status === 2 ? 'Card' : 'Free'}</div>
        },
        {
            field: 'approved',
            headerName: 'Approved',
            flex: 1,
            renderCell: (params) => <div>{params.row?.approved === 1 ? 'Yes' : 'No'}</div>
        },
        {
            field: 'location',
            headerName: 'Location',
            flex: 1,
            renderCell: (params) => <div>{params.row.location.name}</div>
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1,
            renderCell: (params) => <div>{params.row.category.name}</div>
        },
    ];
    const SubColumns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
            renderCell: (params) => <div>{params.row.id}</div>
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            renderCell: (params) => <div>{params.row.price}</div>
        },
        {
            field: 'validTill',
            headerName: 'Valid Till',
            flex: 1,
            renderCell: (params) => <div>{params.row.validTill}</div>
        },
        {
            field: 'name',
            headerName: 'Package Name',
            flex: 1,
            renderCell: (params) => <div>{params.row.package.name}</div>
        },
        {
            field: 'duration',
            headerName: 'Package Duration',
            flex: 1,
            renderCell: (params) => <div>{params.row.package.duration}</div>
        },
        {
            field: 'reference',
            headerName: 'Transaction Reference',
            flex: 1,
            renderCell: (params) => <div>{params.row.transactions[0].reference}</div>
        },
    ];
    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 6,
            bottom: params.isLastVisible ? 0 : 6,
        };
    }, []);
    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ paddingTop: '20px' }}>
                <h3>Ads: </h3>
                <GridToolbarQuickFilter
                    sx={{ border: '#D18D6B' }}
                    quickFilterParser={(searchInput) =>
                        searchInput.split(',').map((value) => value.trim())
                    }
                    quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
                    debounceMs={200}
                />
            </GridToolbarContainer>
        );
    }
    function CustomToolbarSub() {
        return (
            <GridToolbarContainer style={{ paddingTop: '20px' }}>
                <h3>Subscriptions: </h3>
                <GridToolbarQuickFilter
                    sx={{ border: '#D18D6B' }}
                    quickFilterParser={(searchInput) =>
                        searchInput.split(',').map((value) => value.trim())
                    }
                    quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
                    debounceMs={200}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>User {row.id} Details</h2>
                <button className='back' onClick={() => navigate('/')}>Back</button>
            </div>
            <div className='info'>
                <div className='data'>
                    <span className='split left'><p><b>Email: </b>{row.email}</p></span>
                    <span className='split right'><p><b>Mobile: </b>{row.mobile}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Store: </b>{row.store}</p></span>
                    <span className='split right'><p><b>Created Date: </b>{row.createdDate !== undefined ? row.createdDate.slice(0, 10) : ''}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Verified: </b>{row.verified === 1 ? ' Yes' : ' No'}</p></span>
                    <span className='split right'><p><b>Course required: </b>{row.courseRequired === 0 ? 'No' : 'Yes'}</p></span>
                </div>

                {adRows.length !== 0 ?
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <StripedDataGrid
                                sx={{
                                    border: 'none',
                                    height: '40em',
                                }}
                                getRowHeight={() => 'auto'}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector

                                columns={columns}
                                rows={adRows}
                                getRowSpacing={getRowSpacing}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                                components={{ Toolbar: CustomToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '150px' }}>
                        <h3>This user does not have any ads</h3>
                    </div>
                }
                {subRows.length !== 0 ?
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <StripedDataGrid
                                sx={{
                                    border: 'none',
                                    height: '40em',
                                }}
                                getRowHeight={() => 'auto'}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector

                                columns={SubColumns}
                                rows={subRows}
                                getRowSpacing={getRowSpacing}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                                components={{ Toolbar: CustomToolbarSub }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '150px' }}>
                        <h3>This user does not have any subsicriptions</h3>
                    </div>
                }
            </div>
        </div>
    )
}
export default UserPreview