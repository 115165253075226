import "./style.css";
function NotFoundPage() {
  return (
    <div id="not-found-page">
      <h1>404 page not found</h1>
      <h2>There is nothing here</h2>
    </div>
  );
}
export default NotFoundPage;
