import { useParams } from 'react-router-dom';
import axios from './api';
import { useState, useEffect } from 'react';
import { loading } from "@/plugins/global";
import { useNavigate } from "react-router-dom";

function PackagePreview() {
    const params = useParams();
    const navigate = useNavigate();
    const [row, setRow] = useState([])
    useEffect(() => {
        loading(true);
        axios.getPackageById(params.id).then((res) => {
            setRow(res.data.data)
            loading(false);
        }).catch(function (error) {
            console.log(error)
            loading(false);
        })
    }, [])
    
    return (
        <div className='big'>
             <div className='Title-container'>
                <h2>Package {row.name} Details</h2>
                <button className='back' onClick={() => navigate(-1)}>Back</button>
            </div>
            <div className='info'>
                <div className='data'>
                    <span className='split left'><p><b>Name: </b>{row.name}</p></span>
                    <span className='split right'><p><b>Duration (days): </b>{row.duration}</p></span>
                </div>
                <div className='data'>
                <span className='split left'><p><b>Price: </b>{row.price}</p></span>
                <span className='split right'><p><b>Created Date: </b>{row.createdDate !== undefined ? row.createdDate.slice(0,10) : ''}</p></span>
                </div>
                <div className='data'>
                <span className='split left'><p><b>Last Updated Date: </b>{row.lastUpdatedDate !== undefined && row.lastUpdatedDate !== null ? row.lastUpdatedDate.slice(0,10) : ''}</p></span>
                </div>
            </div>
        </div>
    )
}
export default PackagePreview