import axios from './api';
import { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { loading, snackbarMsg } from "@/plugins/global";
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import './style.css';

const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    }
}));

function Notifications() {
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedUsers, setCheckedUsers] = useState([]);

    useEffect(() => {
        loading(true)
        axios.getUsers().then((res) => {
            let tmp = res.data.data 
            tmp.forEach((t)=>{
                t['check']=false 
            })
            setRows(tmp)
            loading(false)
        }).catch(function (error) {
            console.log(error)
            loading(false)
        })
    }, [])
    const columns = [
        {
            field: 'check',
            headerName: 'Check',
            renderCell: (params) => <div style={{ display: 'flex', justifyContent: 'center' }}><FormGroup>
                <FormControlLabel control={<Checkbox
                    sx={{
                        color: 'var(--primary-color)',
                        '&.Mui-checked': {
                            color: 'var(--primary-color)',
                        },
                    }}
                    onChange={(event) => { handleChangeCheckedOne(event,params) }}
                    inputProps={{
                        'aria-label': 'CheckedOne',
                    }}
                />}
                />
            </FormGroup></div>,
            flex: 1,
        },
        {
            field: 'mobile',
            headerName: 'Mobile',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
    ];

    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = () => {
        rows.map((user)=>{
            return user.check ? setCheckedUsers(current=>[...current,user.email]) : setCheckedUsers(current=>[...current])
        })
        setOpen(false);
    }
    const handleClickOpenSelect = () => {
        setOpen(true);
    }
    const handleChangeCheckedAll = (event) => {
        setCheckedAll(event.target.checked);
    };
    const handleChangeCheckedOne = (event,params) => {
        params.row.check = !params.row.check;
    };
    const handleSendButton = () => {

    }
    const getRowSpacing = useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 6,
            bottom: params.isLastVisible ? 0 : 6,
        };
    }, []);

    return (
        <div className='big'>
            <h2>Push Notifications</h2>
            {/* <div style={{ display: 'flex'}}> */}
            <div className='link' onClick={handleClickOpenSelect} ><h3>Select Users</h3></div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField
                    className='textField' 
                    margin="dense"
                    defaultValue={title}
                    label="Title"
                    variant="standard"
                    onChange={(e) => { setTitle(e.target.value) }}
                />
                <TextField
                    className='textArea'
                    margin="dense"
                    defaultValue={message}
                    label="Message"
                    multiline
                    minRows={4}
                    variant="standard"
                    onChange={(e) => { setMessage(e.target.value) }}
                />
            </div>
            <div className='submit'>
                <button className='back' onClick={handleSendButton}>Send</button>
            </div>
            {/* </div> */}
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Check Users</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked
                            sx={{
                                color: 'var(--primary-color)',
                                '&.Mui-checked': {
                                    color: 'var(--primary-color)',
                                },
                            }}
                            checked={checkedAll}
                            onChange={handleChangeCheckedAll}
                            inputProps={{ 'aria-label': 'controlled' }} />} label="All Users" />
                    </FormGroup>
                    {!checkedAll && <div>
                        <div style={{ height: 700, width: '100%' }}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <StripedDataGrid
                                        sx={{
                                            border: 'none',
                                            maxHeight: '55em',
                                        }}
                                        getRowHeight={() => 'auto'}
                                        disableColumnFilter
                                        disableColumnSelector
                                        disableDensitySelector

                                        columns={columns}
                                        rows={rows}
                                        getRowSpacing={getRowSpacing}
                                        getRowClassName={(params) =>
                                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                        }
                                        components={{ Toolbar: GridToolbar }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: { debounceMs: 500 },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button className='buttons' onClick={handleClose}>Cancel</Button>
                    <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default Notifications