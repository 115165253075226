import axios from '@/plugins/http.service';

const axiosFunction = {
    getCategories() {
        return axios().get('category/master');
    },
    getSubCategories() {
        return axios().get('category/all-children/');
    },
    getLocations() {
        return axios().get('location/get-all');
    },
    getConditions() {
        return axios().get('condition/get-all');
    },
    getSizes() {
        return axios().get('size/get-all');
    },
    addAd(data){
        return axios().post('ad/add', data)
    },
    getAds(data){
        return axios().post('ad/get-all', data);
    },
    editAd(data){
        return axios().post('ad/update', data);
    },
    deleteAd(data){
        return axios().post('ad/delete', data);
    },
    getDeletedFiles(id){
        return axios().get('ad/details/' +id)
    }
}
export default axiosFunction