import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import { HOSTURL } from '@/plugins/http.service';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from './api';
import { useNavigate, useParams } from 'react-router-dom';
import { loading, snackbarMsg } from "@/plugins/global";
import Autocomplete from '@mui/material/Autocomplete';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));


function LayoutGrid() {

  const params = useParams();
  const router=useNavigate()
  var data = new FormData();
  const [id, setId] = useState('')
  const [parentId, setParentId] = useState('')
  const [parents, setParents] = useState([])
  const [name, setName] = useState('')
  const [button, setButton] = useState('')
  const [open, setOpen] = useState(false)
  const [row, setRow] = useState([])
  const [parentName, setParentName] = useState()
  const [sizes, setSizes] = React.useState([]);
  const [allSizes, setAllSizes] = React.useState([]);
  const [allSizesNames, setAllSizesNames] = React.useState([]);
  const [haveAds, setHaveAds] = React.useState(false);

  const handleChangeSizes = (event) => {
    const {
      target: { value },
    } = event;
    setSizes(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    loading(true)
    axios.getCategoryById(params.id).then((res) => {
      setRow(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
    axios.getCategories().then((res) => {
      setParents(res.data.data)
    })
    axios.sizes().then((res) => {
      setAllSizes(res.data.data)
      setAllSizesNames(res.data.data.map((el) => el.name))
    })
  }, [])
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
          <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
        </div>
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteCategory(id)
    } else if (button === 'Edit') {
      editCategory(id)
    }
    else {
      addCategory()
    }
  }
  const handleClickOpenEdit = (event, params) => {
    setButton('Edit');
    setOpen(true);
    setId(params.row.id);
    setName(params.row.name);
    setSizes(params.row.sizeCategories.map((el) => el.size.name))
    setParentName({ id: params.row.parent.id, name: params.row.parent.name });
    setParentId(row[0].parent.id)
  }
  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
    let adsLength=params.row.ads.filter((ad)=>ad.status!==0 && ad.archived===0).length
    if(adsLength>0){
      setHaveAds(true)
    }else{
      setHaveAds(false)
    }
  }
  const handleClickAdd = () => {
    setButton('Add')
    setOpen(true)
    setName('')
    setSizes([])
    setParentName({ id: params.id, name: row[0].parent.name });
    setParentId(row[0].parent.id)
  }
  const deleteCategory = (id) => {
    axios.deleteCategory(id).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  function getIdsFromNames(namesArray, idsArray) {
    const nameSet = new Set(namesArray);
    return idsArray.filter(({ name }) => nameSet.has(name)).map(({ id }) => id);
  }

  const addCategory = () => {
    let sizeIds = getIdsFromNames(sizes, allSizes)
    data.append('name', name);
    data.append('parentId', parentId);
    data.append('sizeIds', JSON.stringify(sizeIds));

    axios.addCategory(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot add, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const editCategory = (id) => {
    let sizeIds = getIdsFromNames(sizes, allSizes)
    data.append('name', name);
    data.append('parentId', parentId)
    data.append('sizeIds', JSON.stringify(sizeIds));
    axios.editCategory(id, data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot edit, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);

  return (
    <div className='big'>
      <div className='Title-container'>
        <h2>
          {row.length !== 0 && row[0].parent.name} Sub Categories
        </h2>
        <button onClick={() => handleClickAdd()} >Add</button>
        
      </div>
      <div>
        <button onClick={() => router(-1)} className='backbtn'>Back</button>
      </div>

      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector

              columns={columns}
              rows={row}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{button} Category</DialogTitle>
          <DialogContent style={{ width: '300px' }}>
            {button !== 'Delete' && <div className='dialog-content-container'>
              <div className='dialog-row'>
                <Autocomplete
                  options={parents}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setParentId(value.id);
                  }}
                  defaultValue={parentName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Parent"
                    />
                  )}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  defaultValue={name}
                  id="idName"
                  label="Name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => { setName(e.target.value) }}
                />

                <Select
                  id="size"
                  class="multi-select"
                  style={{ marginTop: "10px" }}
                  multiple
                  value={sizes}
                  variant="standard"
                  onChange={handleChangeSizes}
                  input={<OutlinedInput />}
                  renderValue={(selected) => selected.join(', ')}
                  fullWidth
                >
                  {allSizesNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={sizes.indexOf(name) > -1} className="checbox-primary"/>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>

              </div>
            </div>}
            {button === 'Delete' &&(
              <>
              {haveAds &&(
              <div>This category has assigned ads.</div>
              )}
            <div className='mt-5'>Are you sure you want to delete it?</div>
            </>
            )}
          </DialogContent>
          <DialogActions>
            <Button className='buttons' onClick={handleClose}>Cancel</Button>
            <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}

export default LayoutGrid