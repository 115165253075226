import axios from '@/plugins/http.service';

const axiosFunction = {
    getContents() {
        return axios().get('content/get-all');
    },
    editContent(id,data) {
        return axios().post('content/update/'+id,data);
    },
    deleteContent(data) {
        return axios().post('content/delete/', data);
    },
    getContentById(id) {
        return axios().get('content/details/'+id);
    },
    addContent(data) {
        return axios().post('content/add',data);
    }
}
export default axiosFunction