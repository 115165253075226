import axios from '@/plugins/http.service';

const axiosFunction = {
    getCourses(data) {
        return axios().post('course/get-all', data);
    },
    editCourse(id, data) {
        return axios().post('course/update/' + id, data);
    },
    deleteCourse(data) {
        return axios().post('course/delete/', data);
    },
    addCourse(data) {
        return axios().post('course/add', data);
    },
    getCategories() {
        return axios().get('course-category/master');
    },
    getSubCategories() {
        return axios().get('course-category/all');
    }
}
export default axiosFunction