import axios from '@/plugins/http.service';

const axiosFunction = {
    editUser(id,data) {
        return axios().post('user/update/'+id,data);
    },
    deleteUser(data) {
        return axios().post('user/delete',data);
    },
    getUsers(data) {
        return axios().post('user/get-all', data);
    },
    updateCourse(value) {
        return axios().post('user/update-course-required/'+value);
    },

}
export default axiosFunction