import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from './api'
import { loading } from "@/plugins/global";

const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    }
}));

function LayoutGrid() {
    const navigate = useNavigate();
    const [rows, setRows] = useState([])

    useEffect(() => {
        loading(true)
        axios.getSubscriptions().then((res) => {
            setRows(res.data.data)
            loading(false)
        }).catch(function (error) {
            console.log(error)
            loading(false)
        })
    }, [])
    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
        },
        {
            field: 'packageId',
            headerName: 'Package',
            flex: 1,
            renderCell: (params) => <div>{params.row.package.name}</div>
        },
        {
            field: 'userId',
            headerName: 'User',
            flex: 1,
            renderCell: (params) => <div>{params.row.user.email}</div>
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            flex: 1,
            renderCell: (params) => <div>{params.row.createdDate.slice(0,10)}</div>
        },
        {
            field: 'validTill',
            headerName: 'Validation Date',
            flex: 1,
        },
        {
            field: 'Actions',
            flex: 1,
            headerName: 'Actions',
            renderCell: (params) =>
                <div className='actions'>
                    <IconButton onClick={() => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
                </div>
        },
    ];

   
    const handleClickOpenPreview = (id) => {
        navigate('/admin/users/single-subscription/' + id);
    }
    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 6,
            bottom: params.isLastVisible ? 0 : 6,
        };
    }, []);

    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>Subscriptions</h2>
            </div>
            <div style={{ height: 700, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <StripedDataGrid
                            sx={{
                                border: 'none',
                                height: '55em',
                            }}
                            getRowHeight={() => 'auto'}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector

                            columns={columns}
                            rows={rows}
                            getRowSpacing={getRowSpacing}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )



}

export default LayoutGrid