import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect, useContext } from 'react';
import axios from './api';
import { loading, snackbarMsg } from "@/plugins/global";
import UserContext from "@/context/user";
import Autocomplete from '@mui/material/Autocomplete';
import { HOSTURL } from '@/plugins/http.service';

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));

function LayoutGrid() {
  const navigate = useNavigate();
  var data = new FormData();
  const [files, setFiles] = useState([])
  const [filesName, setFilesName] = useState([])
  let image1, image2, image3;
  const [id, setId] = useState('')
  const [deletedFiles, setDeletedFiles] = useState([])
  const [image, setImage] = useState([])
  const [addImages, setAddImages] = useState([])
  const [deletedImages, setDeletedImages] = useState([])
  const [approved, setApproved] = useState('')
  const [conditionId, setConditionId] = useState()
  const [userId, setUserId] = useState()
  const { admin } = useContext(UserContext);
  const [catOp, setCatOp] = useState('')
  const [condOp, setCondOp] = useState('')
  const [sizeOp, setSizeOp] = useState('')
  const [locOp, setLocOp] = useState('')
  const [approvedOp, setApprovedOp] = useState('')
  const [sizeId, setSizeId] = useState()
  const [categoryId, setCategoryId] = useState()
  const [subCats, setSubCats] = useState([])
  const [name, setName] = useState('')
  const [brand, setBrand] = useState('')
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [locationId, setLocationId] = useState()
  const [approvedId, setApprovedId] = useState()
  const [button, setButton] = useState('')
  const [open, setOpen] = useState(false)
  const [categoryParent, setCategoryParent] = useState([])
  const [parentCategoryObj, setParentCategoryObj] = useState('')
  const [locationParent, setLocationParent] = useState([])
  const [parentLocationObj, setParentLocationObj] = useState('')
  const [conditionParent, setConditionParent] = useState([])
  const [parentConditionObj, setParentConditionObj] = useState('')
  const [sizeParent, setSizeParent] = useState([])
  const [parentSizeObj, setParentSizeObj] = useState('')
  const [rows, setRows] = useState([])
  const approveOptions = ['Yes', 'No'];

  useEffect(() => {
    loading(true)
    data.append('type', 1);
    data.append('approved', 0);
    axios.getAds(data).then((res) => {
      setRows(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
    axios.getCategories().then((res) => {
      setCategoryParent(res.data.data)
    })
    axios.getSubCategories().then((res) => {
      setSubCats(res.data.data)
    })
    axios.getLocations().then((res) => {
      setLocationParent(res.data.data)
    })
    axios.getConditions().then((res) => {
      setConditionParent(res.data.data)
    })
    axios.getSizes().then((res) => {
      setSizeParent(res.data.data)
    })
  }, [])
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'userId',
      headerName: 'User Store',
      flex: 1,
      renderCell: (params) => <div>{params.row.user?.store}</div>
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      renderCell: (params) => <div>{params.row.category.name}</div>
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => <div>{params.row.status === 0 ? 'Pending' : params.row.status === 1 ? 'Subscription' : params.row.status === 2 ? 'Card' : 'Free'}</div>
    },
    {
      field: 'approved',
      headerName: 'Approved',
      flex: 1,
      renderCell: (params) => <div>{params.row.approved === 1 ? 'Yes' : 'No'}</div>
    },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
          <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
          <IconButton onClick={() => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
        </div>
    },
  ];

  const UploadImg = (index) => {
    document.getElementById(index).click();
  };
  const deleteFile = (index) => {
    setFilesName([
      ...filesName.slice(0, index),
      ...filesName.slice(index + 1)
    ]);

    setFiles([
      ...files.slice(0, index),
      ...files.slice(index + 1)
    ]);

    setAddImages([
      ...addImages.slice(0, index),
      ...addImages.slice(index + 1)
    ]);
  }
  const deleteFileUpdate = (index) => {
    if (deletedImages[index].id !== undefined)
      setDeletedFiles((current) => [...current, JSON.stringify(deletedImages[index].id)])

    setDeletedImages([
      ...deletedImages.slice(0, index),
      ...deletedImages.slice(index + 1)
    ]);
    setImage([
      ...image.slice(0, index),
      ...image.slice(index + 1)
    ]);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteAd(id)
    } else if (button === 'Edit') {
      editAd(id)
    }

  }
  const handleClickOpenPreview = (id) => {
    navigate('/admin/ads/single-ad/' + id);
  }

  const handleClickOpenEdit = (event, params) => {
    setButton('Edit');
    setOpen(true);
    setId(params.row.id);
    setName(params.row.name);
    setBrand(params.row.brand);
    setDescription(params.row.description);
    setPrice(params.row.price);
    setDeletedFiles([]);
    axios.getDeletedFiles(params.row.id).then((res) => {
      setDeletedImages(res.data.data[0].adImages)
    })
    if (params.row.approved === 1) {
      setApproved('Yes');
    } else {
      setApproved('No');
    }
    var cat = subCats.map((cat) => cat.id === params.row.categoryId ? cat.name : '')
    if (params.row.categoryId !== null) {
      for (let i = 0; i < cat.length; i++) {
        if (cat[i] !== '') {
          setParentCategoryObj({ id: params.row.categoryId, name: cat[i] });
        }
      }
    } else {
      setParentCategoryObj({ id: '', name: '' });
    }
    setCategoryId(params.row.categoryId);

    var loc = locationParent.map((loc) => loc.id === params.row.locationId ? loc.name : '')
    if (params.row.locationId !== null) {
      for (let i = 0; i < loc.length; i++) {
        if (loc[i] !== '') {
          setParentLocationObj({ id: params.row.locationId, name: loc[i] });
        }
      }
    } else {
      setParentLocationObj({ id: '', name: '' });
    }
    setLocationId(params.row.locationId);

    var con = conditionParent.map((con) => con.id === params.row.conditionId ? con.name : '')
    if (params.row.conditionId !== null) {
      for (let i = 0; i < con.length; i++) {
        if (con[i] !== '') {
          setParentConditionObj({ id: params.row.conditionId, name: con[i] });
        }
      }
    } else {
      setParentConditionObj({ id: '', name: '' });
    }
    setConditionId(params.row.conditionId);

    var size = sizeParent.map((size) => size.id === params.row.sizeId ? size.name : '')
    if (params.row.sizeId !== null) {
      for (let i = 0; i < size.length; i++) {
        if (size[i] !== '') {
          setParentSizeObj({ id: params.row.sizeId, name: size[i] });
        }
      }
    } else {
      setParentSizeObj({ id: '', name: '' });
    }
    setSizeId(params.row.sizeId);

  }
  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
  }
  const deleteAd = (id) => {
    data.append('id', id);
    axios.deleteAd(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }

  const editAd = () => {
    let admin = localStorage.getItem('kinderlandAdmin')
    admin = JSON.parse(admin)

    if (deletedImages.length !== 0) {
      for (let i = 0; i < deletedImages.length; i++) {
        if (deletedImages[i].id === undefined) {
          data.append('image' + i, deletedImages[i]);
        }
      }
    }
    data.append('filesDeleted', JSON.stringify(deletedFiles));
    image1 = files[0];
    image2 = files[1];
    image3 = files[2];
    data.append('image1', image1);
    data.append('image2', image2);
    data.append('id', id);
    data.append('name', name);
    if (brand !== null)
      data.append('brand', brand);
    data.append('description', description);
    data.append('conditionId', conditionId);
    data.append('locationId', locationId);
    data.append('price', price);
    data.append('categoryId', categoryId);
    if (sizeId !== null)
      data.append('sizeId', sizeId);
    data.append('userId', admin.userId);
    if (approved === 'Yes') {
      data.append('approved', 1);
    } else {
      data.append('approved', 0);
    }
    axios.editAd(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot edit, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);

  return (
    <div className='big'>
      <div className='Title-container'>
        <h2> Non Approved Ads</h2>
      </div>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector

              columns={columns}
              rows={rows}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose} >
          <DialogTitle>{button} Ad </DialogTitle>
          <DialogContent>
            {button !== 'Delete' && <div className='dialog-content-container'>
              <div className='dialog-row'>
                <TextField
                  margin="dense"
                  defaultValue={name}
                  label="Name"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 64 }}
                  onChange={(e) => { setName(e.target.value) }}
                />
                <TextField
                  margin="dense"
                  defaultValue={brand}
                  label="Brand"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 64 }}
                  onChange={(e) => { setBrand(e.target.value) }}
                />
                <TextField
                  margin="dense"
                  defaultValue={price}
                  label="Price"
                  fullWidth
                  variant="standard"
                  onChange={(e) => { setPrice(e.target.value) }}
                />
                <TextField
                  margin="dense"
                  defaultValue={description}
                  label="Description"
                  multiline
                  maxRows={12}
                  fullWidth
                  variant="standard"
                  onChange={(e) => { setDescription(e.target.value) }}
                />
                <Autocomplete
                  sx={{ marginRight: 2, paddingTop: '15px' }}
                  options={approveOptions}
                  getOptionLabel={(option) => option || ''}
                  onChange={(event, value) => {
                    if (value === null) {
                      setApproved(null);
                    } else {
                      setApproved(value);
                    }
                  }}
                  defaultValue={approved}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Approved"
                    />
                  )}
                  fullWidth
                />
                <div className='Check' style={{ display: 'flex', paddingTop: '9px' }}>
                  <Autocomplete
                    sx={{ marginRight: 2 }}
                    options={subCats}
                    groupBy={(option) => option.parent.name}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, value) => {
                      if (value === null) {
                        setCategoryId(null);
                      } else {
                        setCategoryId(value.id);
                      }
                    }}
                    defaultValue={parentCategoryObj}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Category"
                        label="Category"
                      />
                    )}
                    fullWidth
                  />
                  <Autocomplete
                    options={locationParent}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, value) => {
                      if (value === null) {
                        setLocationId(null);
                      } else {
                        setLocationId(value.id);
                      }
                    }}
                    defaultValue={parentLocationObj}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Location"
                        label="Location"
                      />
                    )}
                    fullWidth
                  />
                </div>
                <div className='Check' style={{ display: 'flex', paddingTop: '9px', paddingBottom: "13px" }}>
                  <Autocomplete
                    sx={{ marginRight: 2 }}
                    options={conditionParent}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, value) => {
                      if (value === null) {
                        setConditionId(null);
                      } else {
                        setConditionId(value.id);
                      }
                    }}
                    defaultValue={parentConditionObj}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Condition"
                        label="Condition"
                      />
                    )}
                    fullWidth
                  />
                  <Autocomplete
                    options={sizeParent}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, value) => {
                      if (value === null) {
                        setSizeId(null);
                      } else {
                        setSizeId(value.id);
                      }
                    }}
                    defaultValue={parentSizeObj}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Size"
                        label="Size"
                      />
                    )}
                    fullWidth
                  />
                </div>
                {button === 'Add' &&
                  <div>
                    <div style={{ display: 'flex' }}><AttachFileIcon alt="" onClick={() => { UploadImg("file") }} style={{ cursor: 'pointer' }} />Choose Images</div>
                    <input type="file" accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG" id="file" className='inputFile' onChange={(e) => {
                      try {
                        let tmp = Image;
                        tmp = URL.createObjectURL(e.target.files[0]);
                        if (e.target.files.length !== 0) {
                          if (filesName.length < 3) {
                            setFilesName(current => [...current, e.target.files[0].name]);
                            setFiles(current => [...current, e.target.files[0]]);
                            setAddImages(current => [...current, tmp]);
                          } else {
                            setFilesName(current => [...current]);
                            setFiles(current => [...current]);
                            setAddImages(current => [...current]);
                          }
                        }
                      }
                      catch (ex) {
                      }
                    }} style={{ display: "none" }}
                    />
                    <div style={{ display: 'flex' }}>
                      {
                        addImages.map((i, index) => (
                          <div key={index} style={{ display: 'flex' }}>
                            <img src={i} style={{ width: '100px' }} />
                            <DeleteIcon className="iconButton" alt="" onClick={() => { deleteFile(index) }} /></div>
                        ))
                      }
                    </div>
                  </div>
                }
                {button === 'Edit' &&
                  <div>
                    <div style={{ display: 'flex', marginTop: '10px' }}><AttachFileIcon alt="" onClick={() => { UploadImg("fileup") }} style={{ cursor: 'pointer' }} />Choose Images</div>
                    <input type="file" accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG" id="fileup" className='inputFile' onChange={(e) => {
                      try {
                        if (e.target.files.length !== 0) {
                          if (deletedImages.length < 3) {
                            let tmp = Image;
                            tmp = URL.createObjectURL(e.target.files[0]);
                            let pic = { picture: tmp }
                            setDeletedImages(current => [...current, pic]);
                            setFilesName(current => [...current, e.target.files[0].name]);
                            setFiles(current => [...current, e.target.files[0]]);
                            setAddImages(current => [...current, tmp]);

                            // setImage(current => [...current, tmp]);
                          } else {
                            setDeletedImages(current => [...current]);
                            setImage(current => [...current]);
                            setFilesName(current => [...current]);
                            setFiles(current => [...current]);
                            setAddImages(current => [...current]);
                          }
                        }
                      }
                      catch (ex) {
                      }
                    }} style={{ display: "none" }}
                    />
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                      {
                        deletedImages.map((f, index) => (
                          <div key={index} style={{ display: 'flex' }}>
                            {f.id !== undefined ?
                              <img src={HOSTURL + "ad/" + f.picture} style={{ width: '100px' }} />
                              : <img src={f.picture} style={{ width: '100px' }} />
                            }
                            <DeleteIcon className="iconButton" alt="" onClick={() => { deleteFileUpdate(index) }} />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
            }
            {button === 'Delete' && <p>Are you sure you want to delete it?</p>}
          </DialogContent>
          <DialogActions>
            <Button className='buttons' onClick={handleClose}>Cancel</Button>
            <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )



}

export default LayoutGrid