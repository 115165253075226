import * as React from 'react';
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from './api';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { loading, snackbarMsg } from "@/plugins/global";
import { useLocation } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));
function LayoutGrid() {
  var today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  const [from, setFrom] = useState(year.toString() + '-' + month.toString() + '-' + '1');
  const [to, setTo] = useState(year.toString() + '-' + month.toString() + '-' + '30');
  const navigate = useNavigate();
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(false)
  const [required, setRequired] = useState(false)
  const [id, setId] = useState(null)
  const location = useLocation();
  const [button, setButton] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const result = params.get('result');

    console.log(result);
    if (result) {
      snackbarMsg('Updated Successfully')
    }

    loading(true)
    axios.getUsers().then((res) => {
      setRows(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
  }, [location.search])
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'mobile',
      headerName: 'Mobile',
      flex: 1,
    },
    {
      field: 'store',
      headerName: 'Store',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) =>
        <div className='actions'>
          {params.row.type === 2 ? 'Admin' : 'User'}
        </div>
    },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
          {params.row.type === 1 && (
            <>
              <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
              <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
            </>
          )
          }

        </div>
    },
  ];

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
  }

  const handleClickOpenEdit = (event, params) => {
    setButton('Edit')
    setRequired(params.row.courseRequired === 1 ? true : false)
    setOpen(true);
    setId(params.row.id)
  }

  const handleClose = () => {
    setOpen(false);
    setRequired(false)
  };
  const editUser = () => {
    var data = new FormData();
    data.append('courseRequired', required ? 1 : 0);
    axios.editUser(id, data).then((res) => {
      navigate('/')
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot update, something is wrong");
      console.log(error)
    });
  }

  const deleteUser = () => {
    var data = new FormData();
    data.append('userId', id);
    axios.deleteUser(data).then((res) => {
      navigate('/')
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
  }

  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteUser()
    } else if (button === 'Edit') {
      editUser()
    }
  }

  const handleUpdateCourse = (value) => {
    axios.updateCourse(value).then((res) => {
      navigate('/?result=success')
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot update course required, something is wrong");
      console.log(error)
    });
  }

  const filter = () => {
    loading(true);
    console.log(from)
    let fromTemp = typeof from !== 'string' ? from : from.split('-')[0] + "-" + padWithLeadingZeros(from.split('-')[1], 2) + "-" + padWithLeadingZeros(from.split('-')[2], 2) + 'T21:00:00.000Z'
    let toTemp = typeof to !== 'string' ? to : to + 'T21:00:00.000Z'
    let data = {
      from: fromTemp,
      to: toTemp,
    };
    axios.getUsers(data).then((res) => {
      setRows(res.data.data);
      loading(false);
    })
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='stack'>
            {/* <ThemeProvider theme={theme}> */}
            <DatePicker className='testtingo'
              sx={{ margin: 1 }}
              id='from'
              views={['year', 'month']}
              label="From"
              value={from}
              onChange={(newValue) => {
                setFrom(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null}
                sx={{
                  '.MuiInputBase-input': { padding: '5px' },
                }}
              />}
            />
            <DatePicker
              id='to'
              views={['year', 'month']}
              label="To"
              value={to}
              onChange={(newValue) => {
                setTo(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null}
                sx={{
                  '.MuiInputBase-input': { padding: '5px' },
                }}
              />}
            />
            <Button className='button'
              onClick={() => { filter() }}>Filter</Button>
            {/* </ThemeProvider> */}
            <div
              style={{
                width: '2px',
                background: 'rgb(224 224 224)',
                height: '25px',
                margin: '0 5px'
              }}
            ></div>
            <Button className='button w-155' onClick={() => { handleUpdateCourse(1) }}>course required for all users</Button>
            <Button onClick={() => { handleUpdateCourse(0) }} className='backbtn w-155' style={{ marginLeft: '8px' }}>course not required  for all users</Button>
          </div>
        </LocalizationProvider>
        <GridToolbarQuickFilter
          sx={{ border: '#D18D6B' }}
          quickFilterParser={(searchInput) =>
            searchInput.split(',').map((value) => value.trim())
          }
          quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
          debounceMs={200}
        />

      </GridToolbarContainer>
    );
  }
  const handleClickOpenPreview = (id) => {
    navigate('/admin/user-preview/' + id);
  }
  return (
    <div className='big'>
      <div className='Title-container'>
        <h2>Users</h2>
      </div>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              columns={columns}
              rows={rows}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: CustomToolbar }}
            />
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>{button==="Edit" ? 'Edit':'Delete'} User</DialogTitle>
        <DialogContent style={{ width: "400px", maxWidth: "90%" }}>
          {button==="Edit"?(
            <>
          <Checkbox checked={required} onChange={() => { setRequired(!required) }} className="checbox-primary" />
          Make Course Required
          </>
          ):(
            <p>Are you sure you want to delete this user ?</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button className='buttons' onClick={handleClose}>Cancel</Button>
          <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default LayoutGrid