import { useParams } from 'react-router-dom';
import axios from './api';
import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import { loading } from "@/plugins/global";
import { HOSTURL } from '@/plugins/http.service';
import { useNavigate } from "react-router-dom";


function CoursePreview() {
    const params = useParams();
    const navigate = useNavigate();
    const [row, setRow] = useState([]);
    useEffect(() => {
        loading(true);
        axios.getCourseById(params.id).then((res) => {
            setRow(res.data.data)
            document.getElementById("content").innerHTML = res.data.data.content;
            loading(false);
        }).catch(function (error) {
            console.log(error)
            loading(false);
        })
    }, [])


    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>Course {row.name} Details</h2>
                <button className='back' onClick={() => navigate(-1)}>Back</button>
            </div>
            <div className='info'>
                <img src={HOSTURL + "course/" + row.picture} alt='' style={{ width: '300px' }} />
                <div className='data'>
                    <span className='split left'><p><b>Name: </b>{row.name}</p></span>
                    <span className='split right'><p><b>Link: </b><Link href={row.link} target="_blank" rel="noopener noreferrer" color="#D18D6B">{row.link}</Link></p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Category: </b>{row.category?.name}</p></span>
                    <span className='split right'><p><b>Summary: </b>{row.summary}</p></span>
                </div>
                <div className='data'>
                    <div className='split content'><b>Content: </b>
                        <div id='content'></div>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default CoursePreview