import axios from '@/plugins/http.service';

const axiosFunction = {
    getCategories() {
        return axios().get('category/master');
    },
    editCategory(id,data) {
        return axios().post('category/update/'+id,data);
    },
    deleteCategory(id) {
        return axios().get('category/delete/'+id);
    },
    getCategoryById(id) {
        return axios().get('category/'+id);
    },
    addCategory(data) {
        return axios().post('category',data);
    },
    sizes(data) {
        return axios().get('size/get-all',data);
    }

}
export default axiosFunction