import axios from '@/plugins/http.service';

const axiosFunction = {
    getCourseById(id) {
        return axios().get('course/details/' + id);
    },
    getCategoryById(id) {
        return axios().get('course-category/' + id);
    },
}
export default axiosFunction