import { useParams } from 'react-router-dom';
import axios from './api';
import { useState, useEffect } from 'react';
import { loading } from "@/plugins/global";
import { useNavigate } from "react-router-dom";

function SubPreview() {
    const params = useParams();
    const navigate = useNavigate();
    const [row, setRow] = useState([])
    useEffect(() => {
        loading(true);
        axios.getSubById(params.id).then((res) => {
            setRow(res.data.data)
            loading(false);
        }).catch(function (error) {
            console.log(error)
            loading(false);
        })
    }, [])

    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>Subscription {row.id} Details</h2>
                <button className='back' onClick={() => navigate(-1)}>Back</button>
            </div>
            <div className='info'>
                <div className='data'>
                    <span className='split left'><p><b>Package: </b>{row.package?.name}</p></span>
                    <span className='split right'><p><b>User: </b>{row.user?.email}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Price: </b>{row.price} AED</p></span>
                    <span className='split right'><p><b>Created Date: </b>{row.createdDate !== undefined ? row.createdDate.slice(0,10) : ''}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Valid till: </b>{row.validTill}</p></span>
                </div>
            </div>
        </div>
    )
}
export default SubPreview