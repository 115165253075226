import axios from '@/plugins/http.service';

const axiosFunction = {
    getCoupons() {
        return axios().get('coupon/get-all');
    },
    editCoupon(id, data) {
        return axios().post('coupon/update/' + id, data);
    },
    deleteCoupon(data) {
        return axios().post('coupon/delete/', data);
    },
    addCoupon(data) {
        return axios().post('coupon/add', data);
    },
}
export default axiosFunction