import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import PixIcon from '@mui/icons-material/Pix';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AdjustIcon from '@mui/icons-material/Adjust';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GppGoodIcon from '@mui/icons-material/GppGood';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import SchoolIcon from '@mui/icons-material/School';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from './api';
import './style.css';
import { loading, snackbarMsg } from "@/plugins/global";
import { useContext } from 'react';
import UserContext from "@/context/user";
import { useNavigate } from 'react-router-dom';
import './style.css';
import { useEffect } from 'react';


const drawerWidth = 230;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,

});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-around',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function UserLayout({ children }) {
  <CssBaseline />

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [openAds, setOpenAds] = React.useState(false);
  const [openCourses, setOpenCourses] = React.useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openUsers, setOpenUsers] = React.useState(false);
  const { logout: logOut } = useContext(UserContext);

  useEffect(() => {
    // loading(true);
    // axios.getPackageById(params.id).then((res) => {
    //     setRow(res.data.data)
    //     loading(false);
    // }).catch(function (error) {
    //     console.log(error)
    //     loading(false);
    // })
    console.log("hi")
  }, [])


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const goTo = (item) => {
    navigate(item)
  }
  const handleClickAds = () => {
    setOpenAds(!openAds);
  }
  const handleClickCourses = () => {
    setOpenCourses(!openCourses);
  }
  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  }
  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  }
  const handleLogoutButton = () => {
    logOut()
    navigate("/admin/login");
  }

  return (
    <div className='container' style={{ display: "flex" }}>
      <Drawer variant="permanent" open={open} className='drawer'>
        <List className='list'>
          <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
            <ListItemButton onClick={handleClickUsers} sx={{ justifyContent: 'center', px: open ? 2.5 : 5 }}>

              <SupervisorAccountIcon sx={{ pr: open ? 1 : 0 }} />

              <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
              {openUsers ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/users/all-users') }}>

                  <SupervisorAccountIcon sx={{ px: 1 }} />

                  <ListItemText primary="All Users" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/users/subscriptions') }}>

                  <PixIcon sx={{ px: 1 }} />

                  <ListItemText primary="Subscriptions" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/users/transactions') }}>

                  <SwapHorizIcon sx={{ px: 1 }} />

                  <ListItemText primary="Transactions" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/users/coupon') }}>

                  <LocalOfferIcon sx={{ px: 1 }} />

                  <ListItemText primary="Coupons" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
            <ListItemButton onClick={handleClickAds} sx={{ justifyContent: 'center', px: open ? 2.5 : 5 }}>

              <AdjustIcon sx={{ pr: open ? 1 : 0 }} />

              <ListItemText primary="Ads" sx={{ opacity: open ? 1 : 0 }} />
              {openAds ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openAds} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/ads/all-ads') }}>

                  <AdjustIcon sx={{ px: 1 }} />

                  <ListItemText primary="All ads" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          <Collapse in={openAds} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/ads/non-approved-ads') }}>

                  <AdjustIcon sx={{ px: 1 }} />

                  <ListItemText primary="Non Approved Ads" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>


          <Collapse in={openAds} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/ads/categories') }}>

                  <DashboardIcon sx={{ px: 1 }} />

                  <ListItemText primary="Categories" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openAds} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/ads/conditions') }}>

                  <GppGoodIcon sx={{ px: 1 }} />

                  <ListItemText primary="Conditions" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openAds} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/ads/locations') }}>

                  <FmdGoodIcon sx={{ px: 1 }} />

                  <ListItemText primary="Locations" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openAds} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/ads/sizes') }}>

                  <LineWeightIcon sx={{ px: 1 }} />

                  <ListItemText primary="Sizes" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
            <ListItemButton onClick={handleClickCourses} sx={{ justifyContent: 'center', px: open ? 2.5 : 5 }}>

              <SchoolIcon sx={{ pr: open ? 1 : 0 }} />

              <ListItemText primary="Courses" sx={{ opacity: open ? 1 : 0 }} />
              {openCourses ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openCourses} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/courses/all-courses') }}>

                  <SchoolIcon sx={{ px: 1 }} />

                  <ListItemText primary="All courses" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openCourses} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/courses/categories') }}>

                  <DashboardIcon sx={{ px: 1 }} />

                  <ListItemText primary="Categories" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
            <ListItemButton onClick={handleClickSettings} sx={{ justifyContent: 'center', px: open ? 2.5 : 5 }}>

              <SettingsIcon sx={{ pr: open ? 1 : 0 }} />

              <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
              {openSettings ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/settings/settings') }}>

                  <SettingsIcon sx={{ px: 1 }} />

                  <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/settings/content') }}>

                  <TextFieldsIcon sx={{ px: 1 }} />

                  <ListItemText primary="Content" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/settings/packages') }}>

                  <InventoryIcon sx={{ px: 1 }} />

                  <ListItemText primary="Packages" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          {/* <Collapse in={openSettings} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
                  <ListItemButton sx={{ pl: 3 }} onClick={() => { goTo('/admin/settings/notifications') }}>

                    <NotificationsIcon sx={{ px: 1 }} />

                    <ListItemText primary="Push Notifications" sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse> */}
          <ListItem disablePadding sx={{ display: 'block', color: 'white' }}>
            <ListItemButton onClick={() => { handleLogoutButton() }} sx={{ justifyContent: 'center', px: open ? 2.5 : 5 }}>

              <LogoutIcon sx={{ pr: open ? 1 : 0 }} />

              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>

        <IconButton
          onClick={handleDrawerOpen}
          sx={{
            position: 'absolute', bottom: '7px',
            right: '0', left: '0',
            ...(open && { display: 'none' }
            ),
          }}
        >
          <KeyboardDoubleArrowRightIcon sx={{ color: 'white', "&:hover": { backgroundColor: "transparent", } }} />
        </IconButton>
        <IconButton
          onClick={handleDrawerClose}
          sx={{
            position: 'absolute', bottom: '7px',
            right: '0', left: '0',
            ...(!open && { display: 'none' }),
          }}
        >
          <KeyboardDoubleArrowLeftIcon sx={{ color: 'white' }} />
        </IconButton>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>
    </div>
    // </div>
  );
}
