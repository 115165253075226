import { useParams } from 'react-router-dom';
import axios from './api';
import { useState, useEffect } from 'react';
import { loading } from "@/plugins/global";
import { useNavigate } from "react-router-dom";

function TransactionPreview() {
    const params = useParams();
    const navigate = useNavigate();
    const [row, setRow] = useState([])
    useEffect(() => {
        loading(true);
        axios.getTransactionById(params.id).then((res) => {
            setRow(res.data.data)
            loading(false);
        }).catch(function (error) {
            console.log(error)
            loading(false);
        })
    }, [])

    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>Transaction {row.id} Details</h2>
                <button className='back' onClick={() => navigate(-1)}>Back</button>
            </div>
            <div className='info'>
                <div className='data'>
                    <span className='split left'><p><b>Reference: </b>{row.reference}</p></span>
                    <span className='split right'><p><b>User: </b>{row.user?.email}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Subscription Price: </b>{row.subscription?.price}</p></span>
                    <span className='split right'><p><b>Created Date: </b>{row.createdDate !== undefined ? row.createdDate.slice(0,10) : ''}</p></span>
                </div>
                <div className='data'>
                    <span className='split left'><p><b>Amount: </b>{row.amount}</p></span>
                </div>
            </div>
        </div>
    )
}
export default TransactionPreview