import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import axios from './api'
import { loading, snackbarMsg } from "@/plugins/global";
import { EditorState, ContentState, convertFromHTML,convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: 'var(--table-rows)',
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'var(--border)',
    },
  }
}));

function LayoutGrid() {
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState('')
  var data = new FormData();
  const [picture, setImage] = useState('')
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [slug, setSlug] = useState('')
  const [content, setContent] = useState('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [button, setButton] = useState('')
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    loading(true)
    axios.getContents().then((res) => {
      setRows(res.data.data)
      loading(false)
    }).catch(function (error) {
      console.log(error)
      loading(false)
    })
  }, [])
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
        field: 'title',
        headerName: 'Title',
        flex: 1,
      },
    {
      field: 'slug',
      headerName: 'Slug',
      flex: 1,
    },
      {
        field: 'createdDate',
        headerName: 'Created Date',
        flex: 1,
        renderCell: (params) => <div>{params.row.createdDate.slice(0,10)}</div> 
      },
      {
        field: 'lastUpdatedDate',
        headerName: 'Last updated Date',
        flex: 1,
        renderCell: (params) => <div>{params.row.lastUpdatedDate !== undefined && params.row.lastUpdatedDate !== null ? params.row.lastUpdatedDate.slice(0,10) : ''}</div>
      },
    {
      field: 'Actions',
      flex: 1,
      headerName: 'Actions',
      renderCell: (params) =>
        <div className='actions'>
          <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
          <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
          <IconButton onClick={() => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
        </div>
    },
  ];
  const handleClose = () => {
    setOpen(false);
  };
  const handleOnConfirm = () => {
    if (button === 'Delete') {
      deleteContent(id)
    } else if (button === 'Edit') {
      editContent(id)
    }
    else {
      addContent()
    }
  }
  const handleClickOpenPreview = (id) => {
    navigate('/admin/settings/content/' + id);
  }
  const handleClickAdd = () => {
    setButton('Add')
    setOpen(true)
    setTitle('')
    setSlug('')
    setContent('')
  }
  const handleClickOpenEdit = (event, params) => {
    setButton('Edit')
    setOpen(true);
    setId(params.row.id);
    setTitle(params.row.title);
    setSlug(params.row.slug);
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(params.row.content))))
  }
  const handleClickOpenDelete = (event, params) => {
    setButton('Delete')
    setId(params.row.id)
    setOpen(true);
  }
  const deleteContent = (id) => {
    data.append('id', id)
    axios.deleteContent(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot delete, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const addContent = () => {
    data.append('title', title);
    data.append('slug', slug);
    data.append('content', stateToHTML(editorState.getCurrentContent()));
    axios.addContent(data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot add, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const editContent = (id) => {
    data.append('title', title);
    data.append('slug', slug);
    data.append('content', stateToHTML(editorState.getCurrentContent()));
    axios.editContent(id, data).then((res) => {
      window.location.reload(true);
    }).catch(function (error) {
      snackbarMsg("Cannot edit, something is wrong");
      console.log(error)
    });
    setOpen(false);
  }
  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 6,
      bottom: params.isLastVisible ? 0 : 6,
    };
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  };

  return (
    <div className='big'>
      <div className='Title-container'>
        <h2>Content</h2>
        <button onClick={handleClickAdd}>Add</button>
      </div>
      <div style={{ height: 700, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <StripedDataGrid
              sx={{
                border: 'none',
                height: '55em',
              }}
              getRowHeight={() => 'auto'}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector

              columns={columns}
              rows={rows}
              getRowSpacing={getRowSpacing}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
              }
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{button} Content</DialogTitle>
          <DialogContent>
            {button !== 'Delete' && <div className='dialog-content-container'>
              <div className='dialog-row'>
                <TextField
                  margin="dense"
                  defaultValue={title}
                  label="Title"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => { setTitle(e.target.value) }}
                />
                <TextField
                  margin="dense"
                  defaultValue={slug}
                  label="Slug"
                  fullWidth
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                  onChange={(e) => { setSlug(e.target.value) }}
                />
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  placeholder="Content"
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove']
                  }}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                />
              </div>
            </div>}
            {button === 'Delete' && <p>Are you sure you want to delete it?</p>}
          </DialogContent>
          <DialogActions>
            <Button className='buttons' onClick={handleClose}>Cancel</Button>
            <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )



}

export default LayoutGrid