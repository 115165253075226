import * as React from 'react';
import { DataGrid, gridClasses, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormLabel from '@mui/material/FormLabel';
import { useState, useEffect } from 'react';
import axios from './api';
import { loading, snackbarMsg } from "@/plugins/global";

const StripedDataGrid = styled(DataGrid)(() => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: 'var(--table-rows)',
        '&:hover, &.Mui-hovered': {
            backgroundColor: 'var(--border)',
        },
    }
}));

function LayoutGrid() {
    const navigate = useNavigate();
    var data = new FormData();
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [type, setType] = useState('')
    const [discount, setDiscount] = useState()
    const [validFrom, setValidFrom] = useState('')
    const [validTill, setValidTill] = useState('')
    const [useTime, setUseTime] = useState('')
    const [userUseTime, setUserUseTime] = useState('')
    const [button, setButton] = useState('')
    const [open, setOpen] = useState(false)
    const [rows, setRows] = useState([])

    useEffect(() => {
        loading(true)
        axios.getCoupons().then((res) => {
            setRows(res.data.data)
            loading(false)
        }).catch(function (error) {
            console.log(error)
            loading(false)
        })
    }, [])
    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            renderCell: (params) => <div>{params.row.type === 1 ? 'Ads' : params.row.type === 2 ? "Subscription" : ""}</div>
        },
        {
            field: 'discount',
            headerName: 'Discount(%)',
            flex: 1,
            renderCell: (params) => <div>{params.row.type === 1 ? "" : params.row.type === 2 ? params.row.discount : ""}</div>
        },
        {
            field: 'useTime',
            headerName: ' Number Of Users',
            flex: 1,
        },
        {
            field: 'userUseTime',
            headerName: 'Number of Ads',
            flex: 1,
            renderCell: (params) => <div>{params.row.type === 1 ? params.row.userUseTime : params.row.type === 2 ? '' : ""}</div>
        },
        {
            field: 'valid-till',
            headerName: 'Valid Till',
            flex: 1,
            renderCell: (params) => <div>{params.row.validTill.slice(0, 10)}</div>
        },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            flex: 1,
            renderCell: (params) => <div>{params.row.createdDate.slice(0, 10)}</div>
        },
        {
            field: 'lastUpdatedDate',
            headerName: 'Last updated date',
            flex: 1,
            renderCell: (params) => <div>{params.row.lastUpdatedDate !== undefined && params.row.lastUpdatedDate !== null ? params.row.lastUpdatedDate.slice(0, 10) : ''}</div>
        },
        {
            field: 'Actions',
            flex: 1,
            headerName: 'Actions',
            renderCell: (params) =>
                <div className='actions'>
                    <IconButton onClick={(event) => { handleClickOpenEdit(event, params); }}><EditIcon className='iconButton' /></IconButton>
                    <IconButton onClick={(event) => { handleClickOpenDelete(event, params); }}><DeleteIcon className='iconButton' /></IconButton>
                    <IconButton onClick={() => { handleClickOpenPreview(params.row.id); }}><RemoveRedEyeIcon className='iconButton' /></IconButton>
                </div>
        },
    ];
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleOnConfirm = () => {
        if (button === 'Delete') {
            deleteCoupon(id)
        } else if (button === 'Edit') {
            editCoupon(id)
        }
        else {
            addCoupon()
        }
    }
    const handleClickOpenPreview = (id) => {
        navigate('/admin/users/single-coupon/' + id);
    }
    const handleClickAdd = () => {
        var today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        setButton('Add')
        setOpen(true)
        setName('')
        setType('');
        setDiscount('');
        setUseTime('');
        setUserUseTime('');
        setValidFrom(year.toString() + '-' + month.toString() + '-' + day);
        setValidTill(year.toString() + '-' + month.toString() + '-' + day);
    }
    const handleClickOpenEdit = (event, params) => {
        setButton('Edit');
        setOpen(true);
        setId(params.row.id);
        setName(params.row.name);
        if (params.row.type === 1) {
            setType('ads')
        } else if (params.row.type === 2) {
            setType('subscription')
        } else {
            setType('')
        }
        setDiscount(params.row.discount);
        setValidFrom(params.row.validFrom);
        setValidTill(params.row.validTill);
        setUseTime(params.row.useTime);
        setUserUseTime(params.row.userUseTime);
    }
    const handleClickOpenDelete = (event, params) => {
        setButton('Delete')
        setId(params.row.id)
        setOpen(true);
    }
    const deleteCoupon = (id) => {
        data.append('id', id);
        axios.deleteCoupon(data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            snackbarMsg("Cannot delete, something is wrong");
            console.log(error)
        });
        setOpen(false);
    }
    const addCoupon = () => {
        data.append('name', name);
        if (type === 'ads') {
            data.append('discount', 0);
        } else {
            data.append('discount', discount)
        }
        console.log(discount)
        data.append('validFrom', convert(validFrom));
        data.append('validTill', convert(validTill));
        data.append('useTime', useTime);
        if (type === 'ads') {
            data.append('type', 1)
            data.append('userUseTime', userUseTime);
        } else if (type === 'subscription') {
            data.append('type', 2)
            data.append('userUseTime', 1);
        }
        axios.addCoupon(data).then((res) => {
            if (res.data.data.message) {
                snackbarMsg(res.data.data.message);
            } else {
                window.location.reload(true);
                setOpen(false);
            }

        }).catch(function (error) {
            snackbarMsg("Cannot add, something is wrong");
            console.log(error)
        });
        
    }
    const editCoupon = (id) => {
        data.append('name', name);
        data.append('discount', discount);
        data.append('validFrom', convert(validFrom));
        data.append('validTill', convert(validTill));
        data.append('useTime', useTime);
        if (type === 'ads') {
            data.append('type', 1)
            data.append('userUseTime', userUseTime);
        } else if (type === 'subscription') {
            data.append('type', 2)
            data.append('userUseTime', 1);
        }
        axios.editCoupon(id, data).then((res) => {
            window.location.reload(true);
        }).catch(function (error) {
            snackbarMsg("Cannot edit, something is wrong");
            console.log(error)
        });
        setOpen(false);
    }
    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 6,
            bottom: params.isLastVisible ? 0 : 6,
        };
    }, []);

    return (
        <div className='big'>
            <div className='Title-container'>
                <h2>Coupons</h2>
                <button onClick={handleClickAdd}>Add</button>
            </div>
            <div style={{ height: 700, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <StripedDataGrid
                            sx={{
                                border: 'none',
                                height: '55em',
                            }}
                            getRowHeight={() => 'auto'}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector

                            columns={columns}
                            rows={rows}
                            getRowSpacing={getRowSpacing}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </div>
                </div>
                <Dialog open={open} onClose={handleClose} >
                    <DialogTitle>{button} Coupon </DialogTitle>
                    <DialogContent>
                        {button !== 'Delete' && <div className='dialog-content-container'>
                            <div className='dialog-row'>
                                <FormControl style={{ paddingTop: '15px' }}>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Type</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="row-radio-buttons-group"
                                        value={type}
                                        onChange={(event, value) => {
                                            setType(value);
                                        }}
                                    >
                                        <FormControlLabel value="ads" control={<Radio />} label="Ads" />
                                        <FormControlLabel value="subscription" control={<Radio />} label="Subscription" />
                                    </RadioGroup>
                                </FormControl>
                                <TextField
                                    margin="dense"
                                    defaultValue={name}
                                    label="Name"
                                    inputProps={{ maxLength: 24 }}
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => { setName(e.target.value) }}
                                />
                                <TextField
                                    margin="dense"
                                    defaultValue={useTime}
                                    label="Number Of Users"
                                    inputProps={{ maxLength: 24 }}
                                    fullWidth
                                    variant="standard"
                                    onChange={(e) => { setUseTime(e.target.value) }}
                                />

                                {type === 'subscription' &&
                                    <TextField
                                        style={{ marginBottom: '25px' }}
                                        margin="dense"
                                        defaultValue={discount}
                                        label="Discount (%percentage)"
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => { setDiscount(e.target.value) }}
                                    />
                                }
                                {type === 'ads' &&
                                    <TextField
                                        style={{ marginBottom: '25px' }}
                                        margin="dense"
                                        defaultValue={userUseTime}
                                        label="Number of Ads"
                                        inputProps={{ maxLength: 24 }}
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => { setUserUseTime(e.target.value) }}
                                    />
                                }
                                {type !== 'ads' && type !== "subscription" &&
                                    <div style={{ marginTop: "30px" }}></div>
                                }
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className='stack'>
                                        <DatePicker
                                            sx={{ margin: 1 }}
                                            id='from'
                                            views={['day', 'year', 'month']}
                                            label="Valid From"
                                            inputFormat="MM/DD/YYYY"
                                            value={validFrom}
                                            onChange={(newValue) => {
                                                setValidFrom(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} helperText={null}
                                                sx={{
                                                    '.MuiInputBase-input': { padding: '5px' },
                                                }}
                                            />}
                                        />
                                        <DatePicker
                                            sx={{ margin: 1 }}
                                            id='till'
                                            views={['day', 'year', 'month']}
                                            label="Valid Till"
                                            value={validTill}
                                            inputFormat="MM/DD/YYYY"
                                            onChange={(newValue) => {
                                                setValidTill(newValue);
                                                console.log(newValue.toString())
                                            }}
                                            renderInput={(params) => <TextField {...params} helperText={null}
                                                sx={{
                                                    '.MuiInputBase-input': { padding: '5px' },
                                                }}
                                            />}
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>
                        </div>}
                        {button === 'Delete' && <p>Are you sure you want to delete it?</p>}
                    </DialogContent>
                    <DialogActions>
                        <Button className='buttons' onClick={handleClose}>Cancel</Button>
                        <Button className='buttons' onClick={handleOnConfirm}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )



}

export default LayoutGrid